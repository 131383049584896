import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenUtility } from '../../../shared/utility/tokenUtility';

@Component({
  selector: 'app-default-header-dropdown-account',
  templateUrl: './default-header-dropdown-account.component.html',
})
export class DefaultHeaderDropdownAccountComponent implements OnInit {
  public isCompany: boolean = false;
  public company: string = 'W4Y';
  public isGroup: boolean = false;
  public group: string = 'W4Y';
  public isTenant: boolean = false;
  public tenant: string = 'W4Y';
  constructor(private router: Router) { }

  ngOnInit(): void
  {
    if (parseInt(sessionStorage.getItem(TokenUtility.CLIENT_ID)) > 1) {
      this.isCompany = true;
    }

    if (parseInt(sessionStorage.getItem(TokenUtility.ORG_ID)) > 1) {
      this.isGroup = true;
    }

    if (parseInt(sessionStorage.getItem(TokenUtility.TENANT_ID)) > 1) {
      this.isTenant = true;
    }
    this.company = sessionStorage.getItem(TokenUtility.CLIENT);
    this.group = sessionStorage.getItem(TokenUtility.ORG);
    this.tenant = sessionStorage.getItem(TokenUtility.TENANT);
  }

  selectCompanyProfile() {
    this.router.navigate(['profile/company']);
  }

  selectGroupProfile() {
    this.router.navigate(['profile/organization']);
  }

  selectTenantProfile() {
    this.router.navigate(['profile/tenant']);

  }
  logout() {
    this.deleteTokens();
    //alert(location.origin);
    //alert(location.href);
    //alert(location.pathname);
    //this.router.navigateByUrl(location.origin + '/Account/LogOut');
    window.location.reload();
  }

  private deleteTokens() {
    sessionStorage.removeItem(TokenUtility.JWT_TOKEN);
    sessionStorage.removeItem(TokenUtility.REFRESH_TOKEN);
    sessionStorage.removeItem(TokenUtility.SESSIONID);
    sessionStorage.removeItem(TokenUtility.USER_TYPE);
    sessionStorage.removeItem(TokenUtility.USER_NAME);
    sessionStorage.removeItem(TokenUtility.GUID);
    sessionStorage.removeItem(TokenUtility.CLIENT_ID);
    sessionStorage.removeItem(TokenUtility.CLIENT);
    sessionStorage.removeItem(TokenUtility.ORG_ID);
    sessionStorage.removeItem(TokenUtility.ORG);
    sessionStorage.removeItem(TokenUtility.GRP_ID);
    sessionStorage.removeItem(TokenUtility.GRP);
    sessionStorage.removeItem(TokenUtility.TENANT_ID);
    sessionStorage.removeItem(TokenUtility.TENANT);
    sessionStorage.removeItem(TokenUtility.FORNITORE_ID);
    sessionStorage.removeItem(TokenUtility.FORNITORE);
    sessionStorage.removeItem(TokenUtility.LOGO);
    sessionStorage.removeItem(TokenUtility.PROFILE);
  }
}
