import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'loading-spinner',
  templateUrl: 'loading-spinner.component.html',
})
export class LoadingSpinnerComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }

  
}
