export class AccessUser {
  sessionId: string;
  userName: string;
  displayName: string;
  emailId: string;
  password: string;
  host: string;
  name: string;
  surname: string;
}
