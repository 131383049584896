import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { AuthLayoutComponent, DefaultLayoutComponent } from './layouts';
import { AuthGuard } from './views/auth/guard/auth.guard';
import { ClientGuard } from './views/auth/guard/client.guard';
import { GroupGuard } from './views/auth/guard/group.guard';
import { RandomGuard } from './views/auth/guard/random.guard';
import { TenantGuard } from './views/auth/guard/tenant.guard';

export const routes: Routes = [
  //Auth Routes
  {
    path: '',
    component: AuthLayoutComponent,
    data: {
      title: 'Login'
    },
    children: [
      {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full',
      },
      {
        path: 'auth',
        loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule),
        canActivate: [AuthGuard],
      }
    ]
  },
  //App Routes
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('./views/Icons/icons.module').then((m) => m.IconsModule),
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      },
      {
        path: 'product',
        loadChildren: () =>
          import('./views/product/product.module').then((m) => m.ProductModule),
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      },
      {
        path: 'tenant',
        loadChildren: () =>
          import('./views/tenant/tenant.module').then((m) => m.TenantModule),
        canActivate: [TenantGuard],
        canLoad: [TenantGuard]
      },
      {
        path: 'cliente',
        loadChildren: () =>
          import('./views/cliente/cliente.module').then((m) => m.ClienteModule),
        canActivate: [ClientGuard],
        canLoad: [ClientGuard]
      },
      {
        path: 'gruppo',
        loadChildren: () =>
          import('./views/gruppo/gruppo.module').then((m) => m.GruppoModule),
        canActivate: [GroupGuard],
        canLoad: [GroupGuard]
      },
      {
        path: 'service',
        loadChildren: () =>
          import('./views/services/service.module').then((m) => m.ServiceModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./views/profile/profile.module').then((m) => m.ProfileModule),
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
