import { INavData } from '@coreui/angular';

export const navglobalItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Products',
    url: '/product',
    icon: 'cil-drop'
  },
  {
    name: 'Icons',
    url: '/icons',
    icon: 'icon-star',
    children: [
      {
        name: 'Font Awesome',
        url: '/icons/font-awesome',
        icon: 'icon-star',
        badge: {
          variant: 'secondary',
          text: '4.7'
        }
      },
      {
        name: 'Simple Line Icons',
        url: '/icons/simple-line-icons',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Dati dei clienti',
    title: true,
  },
  {
    name: 'Cliente',
    url: '/cliente',
    icon: 'cib-c',
    children: [
      {
        name: 'Anagrafica Società',
        url: '/cliente/anagrafica',
        icon: 'cil-columns'
      },
      {
        name: 'Storici Piano Welfare',
        url: '/cliente/storici',
        icon: 'cil-gift'
      },
      {
        name: 'Dashboard',
        url: '/cliente/dashboard',
        icon: 'cil-columns'
      },
      {
        name: 'GIFTCARD4YOU',
        url: '/cliente/dynamic',
        icon: 'cil-gift'
      },
      {
        name: 'Anagrafica Admin',
        url: '/cliente/admin',
        icon: 'cil-gift'
      },
      {
        name: 'Gestione Piano Welfare',
        url: '/cliente/manage',
        icon: 'cil-gift'
      },
      {
        name: 'Anagrafica Dipendente',
        url: '/cliente/users',
        icon: 'cil-gift'
      },
    ],
  },
  {
    name: 'Cliente',
    url: '/cliente',
    icon: 'cib-c',
    children: [
      {
        name: '+',
        url: '/cliente/scegliere'
      }
    ],
  },
  {
    name: 'Gruppo',
    url: '/gruppo',
    icon: 'cib-justgiving',
    children: [
      {
        name: '+',
        url: '/gruppo/scegliere'
      }
    ],
  },
  {
    name: 'Components',
    title: true,
  },
  {
    name: 'Services',
    url: '/service',
    icon: 'cil-av-timer',
    children: [
      {
        name: 'Products',
        url: '/service/product',
        icon: 'cil-columns'
      },
      {
        name: 'Gift Card',
        url: '/service/giftcard',
        icon: 'cil-gift'
      },
    ],
  }
];

export const navTuttiItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Icons',
    url: '/icons',
    icon: 'icon-star',
    children: [
      {
        name: 'Font Awesome',
        url: '/icons/font-awesome',
        icon: 'icon-star',
        badge: {
          variant: 'secondary',
          text: '4.7'
        }
      },
      {
        name: 'Simple Line Icons',
        url: '/icons/simple-line-icons',
        icon: 'icon-star'
      }
    ]
  },
  //{
  //  name: 'Prof',
  //  url: '/profile',
  //  icon: 'icon-star',
  //  children: [
  //    {
  //      name: 'cliente',
  //      url: '/profile/company',
  //      icon: 'icon-star',
  //    },
  //    {
  //      name: 'gruppo',
  //      url: '/profile/organization',
  //      icon: 'icon-star',
  //    },
  //  ]
  //},
  {
    name: 'HR GLOBAL',
    url: '/tenant',
    icon: 'cib-t-mobile',
    children: [
      {
        name: 'Dashboard',
        url: '/tenant/dashboard',
        icon: 'cil-columns'
      },
    ],
  },
  {
    name: 'HR ADMIN SOCIETÀ',
    url: '/cliente',
    icon: 'cib-c',
    children: [
      {
        name: 'Anagrafica Società',
        url: '/cliente/anagrafica',
        icon: 'cil-columns'
      },
      {
        name: 'Dashboard',
        url: '/cliente/dashboard',
        icon: 'cil-columns'
      },
      {
        name: 'GIFTCARD4YOU',
        url: '/cliente/dynamic',
        icon: 'cil-gift'
      },
      {
        name: 'Anagrafica Hr Admin',
        url: '/cliente/admin',
        icon: 'cil-gift'
      },
      {
        name: 'Gestione Piano Welfare',
        url: '/cliente/manage',
        icon: 'cil-gift'
      },
      {
        name: 'Storico Piani Welfare',
        url: '/cliente/storici',
        icon: 'cil-gift'
      },
      {
        name: 'Anagrafica Dipendente',
        url: '/cliente/users',
        icon: 'cil-gift'
      },
      {
        name: '+ Importa da File',
        url: '/cliente/import',
        icon: 'cil-gift'
      },
      {
        name: 'Pagina - News',
        url: '/cliente/pages',
        icon: 'cil-gift'
      },
      {
        name: 'Configurazione',
        url: '/cliente/config',
        icon: 'cib-c',
        children: [
          {
            name: 'Servizi',
            url: '/cliente/config/cproduct'
          },
          {
            name: 'GiftCard',
            url: '/cliente/config/cgiftcard'
          },
          {
            name: 'Versamenti',
            url: '/cliente/config/cversamenti'
          }
        ],
      },
      {
        name: 'Esporta files',
        url: '/cliente/export',
        icon: 'cil-gift'
      },
      {
        name: 'Utilizzi Conto Welfare',
        url: '/cliente/order',
        icon: 'cil-gift'
      },
      {
        name: 'Email',
        url: '/cliente/email',
        icon: 'cib-koding',
        children: [
          {
            name: 'Template',
            url: '/cliente/email/model'
          },
          {
            name: 'Push',
            url: '/cliente/email/push'
          }
        ],
      },
      {
        name: 'Media',
        url: '/cliente/media',
        icon: 'cil-gift'
      },
    ],
  },
  {
    name: 'HR ADMIN GRUPPO',
    url: '/gruppo',
    icon: 'cib-justgiving',
    children: [
      {
        name: 'Anagrafica Gruppo',
        url: '/gruppo/anagrafica',
        icon: 'cil-columns'
      },
      {
        name: 'Dashboard',
        url: '/gruppo/dashboard',
        icon: 'cil-columns'
      },
      {
        name: 'Gestione Piano Welfare',
        url: '/gruppo/manage',
        icon: 'cil-gift'
      },
      {
        name: 'Anagrafica Dipendente',
        url: '/gruppo/users',
        icon: 'cil-gift'
      },
      {
        name: '+ Importa da File',
        url: '/gruppo/import',
        icon: 'cil-gift'
      },
      {
        name: 'Pagina - News',
        url: '/gruppo/pages',
        icon: 'cil-gift'
      },
      {
        name: 'Utilizzi Conto Welfare',
        url: '/gruppo/order',
        icon: 'cil-gift'
      },
      {
        name: 'Email',
        url: '/gruppo/email',
        icon: 'cib-c',
        children: [
          {
            name: 'Template',
            url: '/gruppo/email/model'
          },
          {
            name: 'Push',
            url: '/gruppo/email/push'
          }
        ],
      },
      {
        name: 'Media',
        url: '/gruppo/media',
        icon: 'cil-gift'
      }
    ],
  }
];

export const navClienteBaseItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'HR ADMIN SOCIETÀ',
    url: '/cliente',
    icon: 'cib-c',
    children: [
      {
        name: 'ANAGRAFICA SOCIETÀ',
        url: '/cliente/anagrafica',
        icon: 'cil-columns'
      },
      {
        name: 'DASHBOARD',
        url: '/cliente/dashboard',
        icon: 'cil-columns'
      },
      {
        name: 'ANAGRAFICA DIPENDENTE',
        url: '/cliente/users',
        icon: 'cil-gift'
      },
      {
        name: 'ESPORTA FILES',
        url: '/cliente/export',
        icon: 'cil-gift'
      },
      {
        name: 'UTILIZZI CONTO WELFARE',
        url: '/cliente/order',
        icon: 'cil-gift'
      },
      {
        name: 'DOCUMENTI',
        url: '/cliente/media',
        icon: 'cil-gift'
      },
    ],
  },
];

export const navClienteMediumItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'HR ADMIN SOCIETÀ',
    url: '/cliente',
    icon: 'cib-c',
    children: [
      {
        name: 'ANAGRAFICA SOCIETÀ',
        url: '/cliente/anagrafica',
        icon: 'cil-columns'
      },
      {
        name: 'DASHBOARD',
        url: '/cliente/dashboard',
        icon: 'cil-columns'
      },
      {
        name: 'GESTIONE PIANO WELFARE',
        url: '/cliente/manage',
        icon: 'cil-gift'
      },
      {
        name: 'ANAGRAFICA DIPENDENTE',
        url: '/cliente/users',
        icon: 'cil-gift'
      },
      {
        name: 'PAGINA E NEWS',
        url: '/cliente/pages',
        icon: 'cil-gift'
      },
      {
        name: 'ESPORTA FILES',
        url: '/cliente/export',
        icon: 'cil-gift'
      },
      {
        name: 'UTILIZZI CONTO WELFARE',
        url: '/cliente/order',
        icon: 'cil-gift'
      },
      {
        name: 'DOCUMENTI',
        url: '/cliente/media',
        icon: 'cil-gift'
      },
    ],
  },
];

export const navClienteHighItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'HR ADMIN SOCIETÀ',
    url: '/cliente',
    icon: 'cib-c',
    children: [
      {
        name: 'ANAGRAFICA SOCIETÀ',
        url: '/cliente/anagrafica',
        icon: 'cil-columns'
      },
      {
        name: 'DASHBOARD',
        url: '/cliente/dashboard',
        icon: 'cil-columns'
      },
      {
        name: 'GESTIONE PIANO WELFARE',
        url: '/cliente/manage',
        icon: 'cil-gift'
      },
      {
        name: 'ANAGRAFICA DIPENDENTE',
        url: '/cliente/users',
        icon: 'cil-gift'
      },
      {
        name: '+ IMPORTA DA FILE',
        url: '/cliente/import',
        icon: 'cil-gift'
      },
      {
        name: 'PAGINA E NEWS',
        url: '/cliente/pages',
        icon: 'cil-gift'
      },
      {
        name: 'CONFIGURAZIONE',
        url: '/cliente/config',
        icon: 'cib-c',
        children: [
          {
            name: 'Servizi',
            url: '/cliente/config/cproduct'
          },
          {
            name: 'GiftCard',
            url: '/cliente/config/cgiftcard'
          },
          {
            name: 'Versamenti',
            url: '/cliente/config/cversamenti'
          }
        ],
      },
      {
        name: 'ESPORTA FILES',
        url: '/cliente/export',
        icon: 'cil-gift'
      },
      {
        name: 'UTILIZZI CONTO WELFARE',
        url: '/cliente/order',
        icon: 'cil-gift'
      },
      {
        name: 'DOCUMENTI',
        url: '/cliente/media',
        icon: 'cil-gift'
      },
      {
        name: 'EMAIL',
        url: '/cliente/email',
        icon: 'cib-koding',
        children: [
          {
            name: 'Invio Mail',
            url: '/cliente/email/push'
          }
        ],
      },
    ],
  },
];

export const navGruppoBaseItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'HR ADMIN GRUPPO',
    url: '/gruppo',
    icon: 'cib-justgiving',
    children: [
      {
        name: 'ANAGRAFICA GRUPPO',
        url: '/gruppo/anagrafica',
        icon: 'cil-columns'
      },
      {
        name: 'DASHBOARD',
        url: '/gruppo/dashboard',
        icon: 'cil-columns'
      },
      {
        name: 'ANAGRAFICA DIPENDENTE',
        url: '/gruppo/users',
        icon: 'cil-gift'
      },
      {
        name: 'UTILIZZI CONTO WELFARE',
        url: '/gruppo/order',
        icon: 'cil-gift'
      },
      {
        name: 'DOCUMENTI',
        url: '/gruppo/media',
        icon: 'cil-gift'
      },
    ],
  },
  {
    name: 'HR ADMIN SOCIETÀ',
    url: '/cliente',
    icon: 'cib-c',
    children: [
      {
        name: 'ANAGRAFICA SOCIETÀ',
        url: '/cliente/anagrafica',
        icon: 'cil-columns'
      },
      {
        name: 'DASHBOARD',
        url: '/cliente/dashboard',
        icon: 'cil-columns'
      },
      {
        name: 'ANAGRAFICA DIPENDENTE',
        url: '/cliente/users',
        icon: 'cil-gift'
      },
      {
        name: 'ESPORTA FILES',
        url: '/cliente/export',
        icon: 'cil-gift'
      },
      {
        name: 'UTILIZZI CONTO WELFARE',
        url: '/cliente/order',
        icon: 'cil-gift'
      },
      {
        name: 'DOCUMENTI',
        url: '/cliente/media',
        icon: 'cil-gift'
      },
    ],
  },
];

export const navGruppoMediumItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'HR ADMIN GRUPPO',
    url: '/gruppo',
    icon: 'cib-justgiving',
    children: [
      {
        name: 'ANAGRAFICA GRUPPO',
        url: '/gruppo/anagrafica',
        icon: 'cil-columns'
      },
      {
        name: 'DASHBOARD',
        url: '/gruppo/dashboard',
        icon: 'cil-columns'
      },
      {
        name: 'GESTIONE PIANO WELFARE',
        url: '/gruppo/manage',
        icon: 'cil-gift'
      },
      {
        name: 'ANAGRAFICA DIPENDENTE',
        url: '/gruppo/users',
        icon: 'cil-gift'
      },
      {
        name: 'PAGINA E NEWS',
        url: '/gruppo/pages',
        icon: 'cil-gift'
      },
      {
        name: 'UTILIZZI CONTO WELFARE',
        url: '/gruppo/order',
        icon: 'cil-gift'
      },
      {
        name: 'DOCUMENTI',
        url: '/gruppo/media',
        icon: 'cil-gift'
      },
    ],
  },
  {
    name: 'HR ADMIN SOCIETÀ',
    url: '/cliente',
    icon: 'cib-c',
    children: [
      {
        name: 'ANAGRAFICA SOCIETÀ',
        url: '/cliente/anagrafica',
        icon: 'cil-columns'
      },
      {
        name: 'DASHBOARD',
        url: '/cliente/dashboard',
        icon: 'cil-columns'
      },
      {
        name: 'GESTIONE PIANO WELFARE',
        url: '/cliente/manage',
        icon: 'cil-gift'
      },
      {
        name: 'ANAGRAFICA DIPENDENTE',
        url: '/cliente/users',
        icon: 'cil-gift'
      },
      {
        name: 'PAGINA E NEWS',
        url: '/cliente/pages',
        icon: 'cil-gift'
      },
      {
        name: 'ESPORTA FILES',
        url: '/cliente/export',
        icon: 'cil-gift'
      },
      {
        name: 'UTILIZZI CONTO WELFARE',
        url: '/cliente/order',
        icon: 'cil-gift'
      },
      {
        name: 'DOCUMENTI',
        url: '/cliente/media',
        icon: 'cil-gift'
      },
    ],
  },
];

export const navGruppoHighItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'HR ADMIN GRUPPO',
    url: '/gruppo',
    icon: 'cib-justgiving',
    children: [
      {
        name: 'ANAGRAFICA GRUPPO',
        url: '/gruppo/anagrafica',
        icon: 'cil-columns'
      },
      {
        name: 'DASHBOARD',
        url: '/gruppo/dashboard',
        icon: 'cil-columns'
      },
      {
        name: 'GESTIONE PIANO WELFARE',
        url: '/gruppo/manage',
        icon: 'cil-gift'
      },
      {
        name: 'ANAGRAFICA DIPENDENTE',
        url: '/gruppo/users',
        icon: 'cil-gift'
      },
      {
        name: '+ IMPORTA DA FILE',
        url: '/gruppo/import',
        icon: 'cil-gift'
      },
      {
        name: 'PAGINA E NEWS',
        url: '/gruppo/pages',
        icon: 'cil-gift'
      },
      {
        name: 'UTILIZZI CONTO WELFARE',
        url: '/gruppo/order',
        icon: 'cil-gift'
      },
      {
        name: 'EMAIL',
        url: '/gruppo/email',
        icon: 'cib-c',
        children: [
          {
            name: 'Invio Mail',
            url: '/gruppo/email/push'
          }
        ],
      },
      {
        name: 'DOCUMENTI',
        url: '/gruppo/media',
        icon: 'cil-gift'
      },
    ],
  },
  {
    name: 'HR ADMIN SOCIETÀ',
    url: '/cliente',
    icon: 'cib-c',
    children: [
      {
        name: 'ANAGRAFICA SOCIETÀ',
        url: '/cliente/anagrafica',
        icon: 'cil-columns'
      },
      {
        name: 'DASHBOARD',
        url: '/cliente/dashboard',
        icon: 'cil-columns'
      },
      {
        name: 'GESTIONE PIANO WELFARE',
        url: '/cliente/manage',
        icon: 'cil-gift'
      },
      {
        name: 'ANAGRAFICA DIPENDENTE',
        url: '/cliente/users',
        icon: 'cil-gift'
      },
      {
        name: '+ IMPORTA DA FILE',
        url: '/cliente/import',
        icon: 'cil-gift'
      },
      {
        name: 'PAGINA E NEWS',
        url: '/cliente/pages',
        icon: 'cil-gift'
      },
      {
        name: 'CONFIGURAZIONE',
        url: '/cliente/config',
        icon: 'cib-c',
        children: [
          {
            name: 'Servizi',
            url: '/cliente/config/cproduct'
          },
          {
            name: 'GiftCard',
            url: '/cliente/config/cgiftcard'
          },
          {
            name: 'Versamenti',
            url: '/cliente/config/cversamenti'
          }
        ],
      },
      {
        name: 'ESPORTA FILES',
        url: '/cliente/export',
        icon: 'cil-gift'
      },
      {
        name: 'UTILIZZI CONTO WELFARE',
        url: '/cliente/order',
        icon: 'cil-gift'
      },
      {
        name: 'EMAIL',
        url: '/cliente/email',
        icon: 'cib-koding',
        children: [
          {
            name: 'Invio Mail',
            url: '/cliente/email/push'
          }
        ],
      },
      {
        name: 'DOCUMENTI',
        url: '/cliente/media',
        icon: 'cil-gift'
      },
    ],
  },
];

export const navgruppoItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Cliente',
    url: '/cliente',
    icon: 'cib-c',
    children: [
      {
        name: '+',
        url: '/cliente/scegliere'
      }
    ],
  },
  {
    name: 'Gruppo',
    url: '/gruppo',
    icon: 'cib-justgiving',
    children: [
      {
        name: '+',
        url: '/gruppo/scegliere'
      }
    ],
  }
];
