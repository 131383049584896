export const UserTypeUtility = {
  ADMIN           : 1,
  EMPLOYEE        : 2,
  ADMINHRBASE     : 4006,
  ADMINHRMEDIUM   : 4007,
  ADMINHRHIGH     : 1002,
  ADMINGHR        : 4009,
  ADMINGHRBASE    : 4010,
  ADMINGHRMEDIUM  : 4011,
  ADMINGHRHIGH    : 4012,
  OPERATOR        : 3002,
  OPERATORBO      : 4005
}

export const UserRoleUtility = {
  ADMIN: 'Admin',
  EMPLOYEE: 'Employee',
  ADMINHRBASE: 'AdminHR - 3',
  ADMINHRMEDIUM: 'AdminHR - 2',
  ADMINHRHIGH: 'AdminHR - 1',
  ADMINGHR: 'AdminHRG - 1',
  ADMINGHRBASE: 'AdminHRG - 2',
  ADMINGHRMEDIUM: 'AdminHRG - 3',
  ADMINGHRHIGH: 'AdminHRG - 4',
  OPERATOR: 'Operator - 1',
  OPERATORBO: 'Operator - 3'
}
