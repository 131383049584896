export const TokenUtility = {
  JWT_TOKEN     : 'JWT_TOKEN',
  REFRESH_TOKEN : 'REFRESH_TOKEN',
  SESSIONID     : 'SESSIONID',
  GUID          : 'GUID',
  USER_NAME     : 'USER_NAME',
  USER_TYPE     : 'USER_TYPE',
  CLIENT_ID     : 'CLIENT_ID',
  CLIENT        : 'CLIENT',
  GRP_ID        : 'GRP_ID',
  GRP           : 'GRP',
  ORG_ID        : 'ORG_ID',
  ORG           : 'ORG',
  TENANT_ID     : 'TENANT_ID',
  TENANT        : 'TENANT',
  FORNITORE_ID  : 'FORNITORE_ID',
  FORNITORE     : 'FORNITORE',
  LOGO          : 'LOGO',
  URL           : 'URL',
  PROFILE       : 'PROFILE'
}
