import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TokenInterceptor } from './token.interceptor';
import { AuthGuard } from './guard/auth.guard';
import { RandomGuard } from './guard/random.guard';
import { LoginComponent } from './login/login.component';
import { AuthService } from '../../service/auth.service';
import { AuthRoutingModule } from './auth-routing.module';
import { ClientGuard } from './guard/client.guard';
import { GroupGuard } from './guard/group.guard';
import { ButtonModule, FormModule } from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TenantGuard } from './guard/tenant.guard';

@NgModule({
  declarations: [LoginComponent],
  providers: [
    AuthGuard,
    AuthService,
    RandomGuard,
    ClientGuard,
    GroupGuard,
    TenantGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    IconModule,
    ButtonModule,
    FormModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    AuthRoutingModule
  ]
})
export class AuthModule { }
