import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { ProfileAuthService } from '../../../service/profile-auth.service';

@Injectable({
  providedIn: 'root'
})
export class ClientGuard implements CanActivate, CanLoad {

  constructor(private authService: ProfileAuthService, private router: Router) { }

  canActivate() {
    return this.canLoad();
  }

  canLoad() {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['auth']);
      return this.authService.isLoggedIn();
    }
    else {
      if (!this.authService.isClientIn()) {
        this.router.navigate(['profile/company']);
      }
      return this.authService.isClientIn();
    }
  }
}
