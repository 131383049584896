import { Component, HostBinding } from '@angular/core';
import { AdminMode, Profile } from '../../shared/utility/profile';
import { TokenUtility } from '../../shared/utility/tokenUtility';

import {  navgruppoItems, navglobalItems, navClienteBaseItems, navClienteMediumItems, navClienteHighItems, navGruppoBaseItems, navGruppoMediumItems, navGruppoHighItems, navTuttiItems } from './_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent {
  @HostBinding('class.c-app') cAppClass = true;
  error: any;
  public navItems = navTuttiItems;

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };
  private _userType: string;
  get userType(): string {
    return this._userType;
  }
  set userType(value: string) {
    this._userType = JSON.parse(sessionStorage.getItem('UserType'));
  }

  //private _userProfile: string;
  //get userProfile(): string {
  //  return this._userProfile;
  //}
  //set userProfile(value: string) {
  //  this._userProfile = sessionStorage.getItem('PROFILE');
  //}
  constructor() {
    this.setNavBar();
  }

  private setNavBar() {
    //alert(JSON.stringify(this.userProfile));
    if (sessionStorage.getItem('PROFILE')) {
      switch (sessionStorage.getItem('PROFILE')) {
          case 'Admin-HR Base': {
            this.navItems = navClienteBaseItems;
            break;
          }
          // HR - Cliente
          case 'Admin-HR Medium': {
            this.navItems = navClienteMediumItems;
            break;
          }
          case 'Admin-HR High': {
            this.navItems = navClienteHighItems;
            break;
          }
          case 'Admin-HRG Base': {
            this.navItems = navGruppoBaseItems;
            break;
          }
          // HR - Gruppo
          case 'Admin-HRG Medium': {
            this.navItems = navGruppoMediumItems;
            break;
          }
          case 'Admin-HRG High': {
            this.navItems = navGruppoHighItems;
            break;
          }
          case 'Operator': {
            this.navItems = navTuttiItems;
            break;
          }
          case 'Admin': {
            this.navItems = navTuttiItems;
            break;
          }
        }
      }
      else {
        sessionStorage.setItem('PROFILE', 'Admin');
        this.navItems = navTuttiItems;
      }
    }
}
