import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { TokenUtility } from '../shared/utility/tokenUtility';

@Injectable
  (
    {
      providedIn: 'root'
    }
  )
export class ProfileAuthService {
  constructor(private http: HttpClient) { }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  isClientIn() {
    return !!(parseInt(this.getClientToken()) > 1);
  }

  isGroupIn() {
    return !!(parseInt(this.getGroupToken()) > 1);
  }

  isTenantIn() {
    return !!(parseInt(this.getTenantToken()) > 1);

  }
  getClientToken() {
    return sessionStorage.getItem(TokenUtility.CLIENT_ID);
  }

  getGroupToken() {
    return sessionStorage.getItem(TokenUtility.ORG_ID);
  }

  getTenantToken() {
    return sessionStorage.getItem(TokenUtility.TENANT_ID);
  }
  getJwtToken() {
    return sessionStorage.getItem(TokenUtility.SESSIONID);
  }
}
