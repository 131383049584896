import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../service/auth.service';
import { AccessUser } from '../../../shared/class/accessUser';
import { Tokens } from '../../../shared/class/token';
import { TokenUtility } from '../../../shared/utility/tokenUtility';
import { UserRoleUtility } from '../../../shared/utility/userTypeUtility';

@Component({
  //selector: 'app-login',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  //@HostBinding('class') cAppClass = 'c-app flex-row align-items-center';
  showSpinner: boolean = false;
  error: any;
  loginForm: FormGroup;
  private _user: AccessUser;
  get user(): AccessUser {
    return this._user;
  }
  set user(value: AccessUser) {
    this._user = value;
  }

  private _tokenInfo: Tokens;
  get tokenInfo(): Tokens {
    return this._tokenInfo;
  }
  set tokenInfo(value: Tokens) {
    this._tokenInfo = value;
  }

  constructor(private authService: AuthService,
              private formBuilder: FormBuilder,
              private router: Router) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  login() {
    this.showSpinner = true;
    this.user = new AccessUser();
    this.user.userName = this.loginForm.controls['username'].value;
    this.user.password = this.loginForm.controls['password'].value;

    this.authService.syncAccess(this.user).subscribe((res: Tokens) => {
      if (res.userType != null && res.userType != undefined && res.userType != UserRoleUtility.EMPLOYEE) {
        this.storeTokens(res);
        this.router.navigate(['dashboard']);
      }
      else {
        alert('Incorrect User Role');
        this.loginForm = this.formBuilder.group({
          username: ['', Validators.required],
          password: ['', Validators.required]
        });
      }
      this.showSpinner = false;
    });

    this.authService.syncAccess(this.user).subscribe({
      next: (res: Tokens) => {
        if (res.userType != null && res.userType != undefined && res.userType != UserRoleUtility.EMPLOYEE) {
          this.storeTokens(res);
          this.router.navigate(['dashboard']);
        }
        else {
          alert('Incorrect User Role');
          this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
          });
        }
        this.showSpinner = false;
      },
      error: (er) => {
        alert(er.error);
        this.showSpinner = false;
      },
      complete: () => {
        
      }
    });
  }

  private storeTokens(tokens: Tokens) {
    sessionStorage.setItem(TokenUtility.JWT_TOKEN, tokens.jwt);
    sessionStorage.setItem(TokenUtility.SESSIONID, tokens.sessionId);
    sessionStorage.setItem(TokenUtility.REFRESH_TOKEN, tokens.refreshToken);
    sessionStorage.setItem(TokenUtility.USER_TYPE, tokens.userType);
    sessionStorage.setItem(TokenUtility.CLIENT_ID, JSON.stringify(tokens.clientID));
    sessionStorage.setItem(TokenUtility.CLIENT, tokens.client);
    sessionStorage.setItem(TokenUtility.ORG_ID, JSON.stringify(tokens.organizationID));
    sessionStorage.setItem(TokenUtility.ORG, tokens.organization);
    sessionStorage.setItem(TokenUtility.TENANT_ID, JSON.stringify(tokens.tenantID));
    sessionStorage.setItem(TokenUtility.TENANT, tokens.tenant);
    sessionStorage.setItem(TokenUtility.FORNITORE_ID, JSON.stringify(tokens.fornitoreID));
    sessionStorage.setItem(TokenUtility.FORNITORE, tokens.fornitore);
    sessionStorage.setItem(TokenUtility.LOGO, tokens.logo);
  }

  loginSync() {
    this.user = new AccessUser();
    this.user.userName = this.loginForm.controls['username'].value;
    this.user.password = this.loginForm.controls['password'].value;
    this.authService.syncAccess(this.user).subscribe(() => this.showSpinner = false);
    this.router.navigate(['dashboard']);
  }
  //private redirectNavigation() {
  //  var jwtToken = sessionStorage.getItem(TokenUtility.JWT_TOKEN);
  //  if (sessionStorage.length != 0 && sessionStorage.getItem(TokenUtility.JWT_TOKEN).length != 0)
  //    this.router.navigate(['dashboard']);
  //  else {
  //    this.removeTokens();
  //    this.router.navigate(['auth']);
  //  }
  //}
}
