import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { of, Observable, throwError } from 'rxjs';
import { catchError, mapTo, retry, tap } from 'rxjs/operators';
//import { config } from '../config';
import { Tokens } from '../shared/class/token';
import { AccessUser } from '../shared/class/accessUser';
import { TokenUtility } from '../shared/utility/tokenUtility';
import { environment } from '../../environments/environment';
import { UserRoleUtility } from '../shared/utility/userTypeUtility';

@Injectable
  (
    {
      providedIn: 'root'
    }
  )
export class AuthService {

  private dataUrl = "/api/APIGateway/";
  private apiUrl: string = environment.baseUrl.default;
  constructor(private http: HttpClient) { }

  public user: AccessUser;

  access(user: AccessUser) {
    let url = this.apiUrl + '/api/APIGateway/v1/AccessRequest';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<Tokens>(url, user, {
      headers: headers
    }).subscribe((res: Tokens) => {

      if (res.userType != null && res.userType != undefined && res.userType != UserRoleUtility.EMPLOYEE) {
        this.storeTokens(res)
      }
      else {
        alert('Incorrect User Role');
      }
    });
  }

  syncAccess(user: AccessUser): Observable<any> {

    let url = this.apiUrl + '/api/APIGateway/v1/AccessRequest';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<Tokens>(url, user, {
      headers: headers
    });
  }

  login(user: AccessUser): Observable<Tokens> {

    user.userName = "adminW4Y";
    user.password = "Admin@w4you";
    let url = this.dataUrl + 'AccessRequest';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var tokenItem = this.http.post<Tokens>(url, user, {
      headers: headers
    });
    return tokenItem;
  }

  logout(): boolean {
    this.deleteTokens();
    return !this.getJwtToken()
  }


  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    let url = this.dataUrl + 'RefreshRequest';
    return this.http.post<any>(url, {
      'refreshToken': this.getRefreshToken()
    }).pipe(tap((tokens: Tokens) => {
      this.storeJwtToken(tokens.jwt);
    }));
  }

  getJwtToken() {
    return sessionStorage.getItem(TokenUtility.SESSIONID);
  }


  private getRefreshToken() {
    return sessionStorage.getItem(TokenUtility.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    sessionStorage.setItem(TokenUtility.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    sessionStorage.setItem(TokenUtility.JWT_TOKEN, tokens.jwt);
    sessionStorage.setItem(TokenUtility.SESSIONID, tokens.sessionId);
    sessionStorage.setItem(TokenUtility.REFRESH_TOKEN, tokens.refreshToken);
    sessionStorage.setItem(TokenUtility.USER_TYPE, tokens.userType);
    sessionStorage.setItem(TokenUtility.CLIENT_ID, JSON.stringify(tokens.clientID));
    sessionStorage.setItem(TokenUtility.CLIENT, tokens.client);
    sessionStorage.setItem(TokenUtility.ORG_ID, JSON.stringify(tokens.organizationID));
    sessionStorage.setItem(TokenUtility.ORG, tokens.organization);
    sessionStorage.setItem(TokenUtility.TENANT_ID, JSON.stringify(tokens.tenantID));
    sessionStorage.setItem(TokenUtility.TENANT, tokens.tenant);
    sessionStorage.setItem(TokenUtility.FORNITORE_ID, JSON.stringify(tokens.fornitoreID));
    sessionStorage.setItem(TokenUtility.FORNITORE, tokens.fornitore);
    sessionStorage.setItem(TokenUtility.LOGO, tokens.logo);
  }

  private async deleteTokens() {
    sessionStorage.removeItem(TokenUtility.JWT_TOKEN);
    sessionStorage.removeItem(TokenUtility.REFRESH_TOKEN);
    sessionStorage.removeItem(TokenUtility.REFRESH_TOKEN);
    sessionStorage.removeItem(TokenUtility.USER_TYPE);
    sessionStorage.removeItem(TokenUtility.CLIENT_ID);
    sessionStorage.removeItem(TokenUtility.CLIENT);
    sessionStorage.removeItem(TokenUtility.ORG_ID);
    sessionStorage.removeItem(TokenUtility.ORG);
    sessionStorage.removeItem(TokenUtility.TENANT_ID);
    sessionStorage.removeItem(TokenUtility.TENANT);
    sessionStorage.removeItem(TokenUtility.FORNITORE_ID);
    sessionStorage.removeItem(TokenUtility.FORNITORE);
    sessionStorage.removeItem(TokenUtility.LOGO);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
